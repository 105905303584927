/* Consult requests */

.ConsultRequestCard{
  &__New{
    border-color:#a8b4ec;
  }
}

.Admin.ConsultRequest {

  height:100vh;

  .ActivityLog{
  }

  .Tabs {
    height: 90px;
    background-color: #F7F9FA;
    display: flex;
    justify-content: space-between;
    padding: 17px 80px 0 23px;
  }

  .ConsultRequestTab {
    width: 19%;
    background-color: #E8EEFA;
    padding: 10px 0px 0 33px;
    letter-spacing: 1px;
    display:flex;
    justify-content: center;

    &--inactive {
      background-color: #D4DFFD;
      &:hover{
        cursor:pointer;
      }
    }

    &--active {
      background: white;
      position: relative;
    }

    &__time {
      align-self:center;
      display: inline-block;
      font-size: 17px;
      font-weight:bolder;
      margin-right: 30px;
      margin-bottom: 3px;
    }

    &__patient-name {
      font-size: 14px;
    }
  }

  .TabContent {
    padding: 58px 58px 0 3.75vw;
    background-color: white;

    .TabContentWrapper{

    }
  }

  .ContentWrapper{
    grid-row: 2 / end;
    grid-column: 2 / end;
    background-color: #FFFFFF;
  }

  .ConsultRequest__newRequestBar{
    display:flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    &__requestButton{
      display:flex;
      align-items:center;

      &__text{
        padding-right:15px;
        color: rgb(155,155,155);
      }

      svg{
        height:18px;
        width:20px;
        fill:rgb(155,155,155);
      }
    }
  }

  .ConsultRequest__controlsBar{
  	margin-bottom:40px;
    display:flex;
    justify-content: space-between;

    .SearchInput{
    	&__search-wrapper{
    		position:relative;
    		display:inline-block;
    		width: 350px;
    	}

    	&__search{
    	    position: relative;
    	    height: 32px;
    	    background-color: #e6edf0;
    	    background-image: none;
    	    /*border: 1px solid #ccc;*/
    	    border-radius:100px;
    	    box-shadow:none;
    	    border:none;
    	    padding-left:38px;

          &::placeholder{
            text-transform: capitalize;
          }

    	}

    	&__search-icon {
    	    position: absolute;
    	    top: 0;
    	    bottom: 1px;
    	    margin: auto;
    	    z-index: 1;
    	    left: 14px;
    	    color: white;
    	    line-height: 32px;
    	    font-size: 14px;
    	    color: #999;
    	}
    }
  }

  .Pagination {
    display:flex;
    height: 100%;
    justify-content: flex-end;

    &__PageNumber{
      margin: 0 5px;

      .Select-arrow-zone{
        svg{
          vertical-align: middle;
          font-size:21px;
        }
      }
    }

    &__currentPage{
      display:flex;
      align-items:center;
      padding-right:35px;
      font-size: 16px;
      letter-spacing: .5px;
    }

    &__controls{
      button{
        width:35px;
        height:32px;
        background-color: #F7F9FA;
        border: 1px solid #E6EDF0;
        border-radius:3px;

        &:disabled{
          color: #999;
        }

        svg{
          height:32px;
          width:25px;
        }

        &.prevPageButton{
          margin-right:5px;
        }
      }

    }
  }

  .ConsultRequestContent{
    width:100%
  }

  .cell {
    &.requestPatientName {
      flex:3;
    }

    &.requestPractice{
      flex:2;
    }

    &.requestDate {
      flex:3;
    }

    &.requestStartDate {
      flex:2;
    }

    &.requestSteps{
      flex:1;
    }

    &.requestCurrentStep{
      flex:2;
    }


    &.requestDescription{
      flex:5;
      &>span{
        max-height:40px;
        overflow:hidden;
      }
    }

    &.one{
      flex: 1;
    }

    &.two{
      flex: 2;
    }

    &.three{
      flex: 3;
    }

    &.four{
      flex: 4;
    }

    &.five{
      flex: 5;
    }

    &.paymentStatus{
      flex:2;
    }

    &.requestStatus{
      flex:2;
    }
  }


}
