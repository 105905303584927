.UserProfilePage{
  height:100vh;
  display: grid;
  grid-template-columns: 90px auto [end];
  grid-template-rows: 72px auto [end];

  .Header{
    grid-row: 1 / span 1;
    grid-column: 1 / end;
  }

  .Sidebar{
    grid-row: 2 / end;
    grid-column: 1 / span 1;
  }

  &__ContentWrapper{
    background-color: #FFFFFF;
    padding-top: 70px;
    padding-left: 50px;
    padding-right: 50px;
    grid-row: 2 / end;
    grid-column: 2 / end;
  }

  &__heading{

    &__title{
      font-size: 15px;
      font-family: 'Proxima Nova Bold';
      /* font-weight: bolder; */
      letter-spacing: 1px;
      color: #3D525A;
      margin-bottom: 26px;
    }

    &__name{
      font-size: 24px;
      font-weight: bolder;
      margin-bottom: 18px;
    }
  }

  &__form{
    background-color: #F7F9FA;
    padding: 50px 100px;
    border: 1px solid #E6EDF0;
    border-radius: 5px;

    &__details{
      display:flex;
      flex-direction:column;

      &__row{
        display:flex;

        &-language{
          min-width:150px;
        }

        &-emailAddress{
          min-width:300px;
        }

        &-address{
          min-width:550px;
        }

        &-phoneNumber{
          min-width:300px;
        }
      }
    }
  }

}
