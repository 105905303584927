.ConsultRequestList {
  display: flex;
  flex-direction: column;

  .ConsultRequestCard{
    &__New{
      border-color:#a8b4ec;
    }
  }

  .noResults{
    text-align:center;
    padding-top:50px;
    font-size:16px;
  }

  .row {
    display: flex;
    min-height: 70px;
    padding-left:25px;
    margin-left:0;
    margin-right:0;
  }

  .row:first-child{
    min-height: 50px;
  }

  .cell {
    flex: 4;
  }

  .cell.span4-5 {
    flex: 8 24px;                    /*  col 4,5 flex-grow/border/padding  */
  }

  .cell.span3-4 {
    flex: 8 24px;                    /*  col 3,4 flex-grow/border/padding  */
  }

  .cell.span3-5 {
    flex: 12 36px;                   /*  col 3,4,5 flex-grow/border/padding  */
  }

  .row .cell {
    display: flex;
    justify-content: flex-start;         /*  center horiz. */
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
  }
}

.ConsultRequestCard {
  //width: 860px;
  /*height: 250px;*/
  //padding: 40px 40px 20px; /* top right/left bottom*/
  margin-bottom: 5px;
  //margin-left: 10px;
  background-color: #F7F9FA;
  border-radius: 5px;
  border: 1px solid #E6EDF0;
  //box-shadow: 0 0 10px 3px #E6EDF0;
}
