.ConfirmPopUp{
  padding: 30px 50px;
  background-color: #FAFAFA;
  text-align: left;
  width: 460px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #333;
  border-radius: 4px;




  .Select{
    text-align:left
  }

  &__SelectRow{
    text-align:left
  }

  &__title{
    font-weight: 700;
    font-size:16px;
  }

  &__body{
    margin-top: 30px;

    textarea{
      resize:none;
      &.Input_Error{
        border-color: #d0021b;
      }
    }

    &-textInput{
      margin-top:10px;
    }

    &-select{
      margin-top:15px;
    }
  }

  &__footer{
    display:flex;
    justify-content: center;
    margin-top: 30px;

    &__button{
      padding: 10px 25px;
      width: 130px;
      background-color: black;
      text-align: center;
      border-radius: 100px;
      color: white;
      letter-spacing: 1.5px;
      font-family: "Proxima Nova", Georgia, sans-serif;

      &:first-child{
        margin-right: 15px;
      }
    }
  }
}
