@font-face{
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima-Nova-Regular.eot');
	src: url('../fonts/Proxima-Nova-Regular.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/Proxima-Nova-Regular.woff') format('woff'),
	     url('../fonts/Proxima-Nova-Regular.ttf') format('truetype'),
	     url('../fonts/Proxima-Nova-Regular.svg#webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face{
	font-family: 'Proxima Nova';
	src: url('../fonts/Proxima-Nova-Semibold.eot');
	src: url('../fonts/Proxima-Nova-Semibold.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/Proxima-Nova-Semibold.woff') format('woff'),
	     url('../fonts/Proxima-Nova-Semibold.ttf') format('truetype'),
	     url('../fonts/Proxima-Nova-Semibold.svg#webfont') format('svg');
    font-weight: bolder;
    font-style: normal;
}

@font-face{
	font-family: 'Proxima Nova Semibold';
	src: url('../fonts/Proxima-Nova-Semibold.eot');
	src: url('../fonts/Proxima-Nova-Semibold.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/Proxima-Nova-Semibold.woff') format('woff'),
	     url('../fonts/Proxima-Nova-Semibold.ttf') format('truetype'),
	     url('../fonts/Proxima-Nova-Semibold.svg#webfont') format('svg');
    font-style: normal;
}

@font-face{
	font-family: 'Proxima Nova Bold';
	src: url('../fonts/Proxima-Nova-Bold.eot');
	src: url('../fonts/Proxima-Nova-Bold.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/Proxima-Nova-Bold.woff') format('woff'),
	     url('../fonts/Proxima-Nova-Bold.ttf') format('truetype'),
	     url('../fonts/Proxima-Nova-Bold.svg#webfont') format('svg');
}

@font-face{
	font-family: 'Proxima Nova Thin';
	src: url('../fonts/Proxima-Nova-Thin.eot');
	src: url('../fonts/Proxima-Nova-Thin.eot?#iefix') format('embedded-opentype'),
	     url('../fonts/Proxima-Nova-Thin.woff') format('woff'),
	     url('../fonts/Proxima-Nova-Thin.ttf') format('truetype'),
	     url('../fonts/Proxima-Nova-Thin.svg#webfont') format('svg');
}
