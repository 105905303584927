.ActivityLog{

  //padding: 10px 0 10px;
  display:flex;
  flex-direction: column;
  align-items: center;
  background-color: #e6edf0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  text-align:left;
  overflow-y: scroll;
  max-height: 40vh;
  position: relative;

  &::-webkit-scrollbar {
   width: 0.5em;
   height: 0.5em;
   position: absolute;
   right: 0;
  }

  &::-webkit-scrollbar-thumb {
   background-color: rgba(0,0,0,.1);
   border-radius: 10px;

   &:hover {
    /*background-color: rgba(0,0,0,.1);*/
    cursor: default;
   }
  }

  &__Message{
    padding: 10px 15px;
    width:300px;
    border-bottom: solid 1px lightgrey;
    color: #12171a;
    font-size:11px;
    font-family: 'Proxima Nova';

    &-read{
       background-color:#ffffff;
    }

    &:hover{
      cursor: pointer;
      opacity: .93;
      /*background-color: rgba(239,239,239,.5)*/
    }

    &__Date{
      padding-top:10px;
      text-align:right;
      font-size:10px;
      color:gray;
    }
    &:last-child{
      border-bottom: none;
    }
  }

}
