@page {
  size: A4;
}

.WorkRequestForm{
  text-align:center;

  &__actionsRow{
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
  }
}

th{
  font-size:18px;
  font-weight:700;
  text-transform: capitalize;
}

td{
  font-size:16px;

}

.WorkRequestForm{
  text-align:center;



  .divider{
    border-bottom:1px solid black;
  }

  &__heading{
    font-size: 36px;
    margin-top:40px;
    margin-bottom: 10px;
  }

  &__label{
    font-weight: 700;
    text-transform: capitalize;
  }

  &__labname{
    font-size: 18px;
  }

  &__requestId{
    font-size: 18px;
  }

  &__table{
    margin-bottom: 20px;
    text-align:left;


    thead{

      th{
        padding-bottom: 10px;
      }
    }


    &__row{

      &-clinic{
        margin-top:30px;
      }

      &-patient{
        tr, td{
          min-width: 200px;
        }
      }

      &-dates{
        tr, td{
          min-width: 200px;
        }
      }

      &-description{
        width:100%;
        padding-bottom: 30px;
      }
    }

  }

  &__dialog{
    width: 800px;
    height: 1131px;
    background-color: white;
    color: black;

    .popup-dialog__content{
      padding-top: 0;
    }


    &__content{
      color: black;
      background-color: white;
    }
  }

  &__button{
    margin-top: 10px;
    padding: 10px 40px;
    background-color: black;
    text-align: center;
    border-radius: 100px;
    color: white;
    letter-spacing: 1.5px;
    font-family: "Proxima Nova", Georgia, sans-serif;
    margin-right: 20px;

    &:last-child{
      margin-right:0;
    }
  }

  &__ToothCharts{
    /*margin: 50px 0 100px 0;
    padding: 26px 50px 50px 50px;
    background-color: #FCFEFF;
    border-radius: 4px;*/

    margin-top:30px;

    &__heading{
      &>span
        {
          font-size: 17px;
          text-transform: capitalize;
        }
    }

    &__charts{
      display:flex;
      justify-content: space-evenly;
      text-align: center;
    }
  }
}

th{
  font-size:18px;
  font-weight:700;
  text-transform: capitalize;
}

td{
  font-size:16px;

}

@media print {

  body {
      width: 21cm;
      height: 29.7cm;
      /*margin: 30mm 45mm 30mm 45mm;*/
      background-color: white;
      color: black;
      /* change the margins as you want them to be. */
      margin: 60mm 75mm 60mm 75mm;
  }

  .WorkRequestForm{
    text-align:center;

    margin-left: 100px;
    margin-right: 100px;
    margin-top: 50px;

    //margin: 30mm 45mm 30mm 45mm;

    &__ToothCharts{
      /*margin: 50px 0 100px 0;
      padding: 26px 50px 50px 50px;
      background-color: #FCFEFF;
      border-radius: 4px;*/

      &__heading{
        &>span
          {
            font-size: 17px;
            text-transform: capitalize;
          }
      }

      &__charts{
        display:flex;
        justify-content: space-evenly;
        text-align: center;
      }
    }
  }

  th{
    font-size:18px;
    font-weight:700;
    text-transform: capitalize;
  }

  td{
    font-size:16px;

  }


}
