.NewConsultRequest{

    height: 100vh;
    display: grid;
    grid-template-columns: 90px auto [end];
    grid-template-rows: 72px auto [end];

  .Header {
    grid-row: 1 / span 1;
    grid-column: 1 / end;
  }

  .Sidebar {
    grid-row: 2 / end;
    grid-column: 1 / span 1;
  }

  .is-searchable{
    .Select-control{
      padding-top:0px;
    }
  }

  .SearchInput{
    .Select-multi-value-wrapper{
      padding-top:0px;
    }
  }

  &__ContentWrapper{
  	background-color:#FFFFFF;
  	padding-top:70px;
  	padding-left:50px;
    padding-right:50px;
    grid-row: 2 / end;
    grid-column: 2 / end;
  }

  &__DetailHeader{
    //TODO: color:
    padding-bottom:10px;
  }

  &__ImagesHeader{
    padding-bottom:10px;
  }

  .RequestTypeForm{

    &__row{

      &__buttons{
        display:flex;
        flex-direction: column;
        align-items:start;
      }

    }

    &__title{
      margin-bottom: 30px;
      font-size: 24px;

    }

    &__button{
        width:unset;
        color: #12171A;
        border: 1px solid #273238;
        padding: 20px 30px;
        margin-bottom: 30px;

      &__text{
        font-size: 18px;
        color: white;
      }
    }

  }

  .ImageSetUpload{
    margin-top:25px;
    padding: 0;

    &__formContainer{
      width:100%;
    }

    &__form{
      display:flex;
      align-items: center;
      justify-content:center;
      background-color: #f7f9fa;
      border-radius: 3px;
      border: solid 1px #e6edf0;
      padding: 50px 0;

      &__actions{
        align-self: flex-end;
      }
    }
  }

  &__form{
    background-color: #F7F9FA;
    padding: 30px 100px;
    border: 1px solid #E6EDF0;
    border-radius: 5px;

    &__toggleButtonGroup{
      width:100%;

      &__toggleButton{
        width:50%;
        border-color:#e6edf0;
        padding: 9px 12px;
        border-radius:3px;
        box-shadow: none;

        &-three{
          width:25%;
        }
      }
    }

    &__details{
      display:flex;
      flex-direction: column;

      &__row{
        display:flex;

        &:first-child{
          justify-content:flex-end;

        }

      }

      .Error__Label{
        height:28px;
      }

      &__leftColumn{
        min-width:500px;
        padding-right:75px;

        &__row{
          //max-width:300px;
          //
          .DayPickerInput{
            width:100%;

            &-Overlay{
              width:100%;
            }
          }

          .DayPicker{
            width:100%;
          }
        }

        &__halfrow{
          display:flex;
          justify-content: space-between;

          &> div{
              width:45%;
          }
        }
      }

      &__rightColumn{
        flex-grow: 1;

        textarea{
          resize:none;
          height:230px;

          &.Input_Error{
            border-color:#F93D5C;
          }
        }
      }

    }

    &__images{

      &__row{
        margin-bottom: 15px;
        display:flex;
        flex-direction: row;

        .SingleImageUploadBox{
          display:flex;
          //flex-grow: 1;
          flex-direction: column;
          border: 1px solid #E6EDF0;
          border-left: none;
          width:20%;

          &__header{
            text-align:center;
            padding: 15px 0;
            background-color: #d2dde2;
          }

          &__detail{
            height: 200px;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &__dropzone{
            height: 100%;
            border: none;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            width:50px;
            height:50px;

            .dz-message{

              display:flex;
              justify-content: center;

              .default-icon{

              }

              &.dz-default{
                display:none;
              }
            }
            .dz-preview{
              &.dz-error{
                .dz-error-mark{
                  &__customErrorMark{
                    margin-left: 0;
                    margin-top:0;
                  }
                }
              }
              &.dz-success{
                .dz-success-mark{
                  &__customSuccessMark{
                    margin-left:0;
                    margin-top:0;
                  }
                }
              }
              .dz-image{
                &__customImagePreview{
                  width:100%;
                  min-width:100px;
                  height:100%;
                }

              }
              .dz-details{
                &__customImageDetails{
                  padding: 0;

                  .removeIcon{
                    height: 3em;
                    .iconWrapper{
                      &:hover{
                        cursor: pointer;
                      }
                      top: 5px;
                      right: 5px;
                      position: absolute;
                      width:24px;
                      height:24px;
                      svg{
                        pointer-events:none;
                        fill: rgb(155,155,155);
                        color: black;
                        //z-index: 21; /* img is at 10, detamils is at 20*/
                      }
                    }
                  }
                  .dz-size{
                    &__customImageSize{
                      margin-bottom: 1em;
                      margin-left: 1em;
                      margin-right: 1em;
                    }
                  }
                  .dz-filename{
                    &__customFilename{

                    }
                  }
                }
              }

            }
          }
        }

        .SingleImageUploadBox:first-child{
          border-left:1px solid #E6EDF0;
        }

        .SingleImageUploadBox:last-child{

        }

      }

    }

    &__actions{
      display:flex;
      justify-content: flex-end;
      padding-top: 30px;
      padding-bottom:100px;

      button{
        padding: 10px 0;
        width: 150px;
        background-color: #273238;;
        text-align: center;
        border-radius: 27px;
        color:white;
        letter-spacing:1.5px;
      }
      &__submitButton{
        margin-left:10px;
      }

    }
  }



}
