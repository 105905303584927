.ToothSet{
  display:flex;
  flex-direction: column;

  &__UPPER{
    #toothLow{
      pointer-events: none;
      stroke: lightgray;

      line.cls-2{
        stroke:lightgray;
      }

      .tooth {
        stroke: lightgray;
      }
    }
  }

  &__LOWER{

    #toothUp{
      pointer-events: none;
      stroke: lightgray;

      line.cls-2{
        stroke:lightgray;
      }

      .tooth {
        stroke: lightgray;
      }


    }
  }

}

.ToothMovementChart{

  &__loadingOverlay{
    position: absolute;
    width:100%;
    height:100%;
    background-color: gray;
    border-radius: 100px;
    opacity: 0.2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__heading{
    margin-bottom: 20px;
    margin-top:20px;

    span{
      margin-left: 3px;
      color: gray;
    }
  }

  &__charting{
    position:relative;
  }

  &__buttons{
    margin-bottom: 20px;
    margin-top:20px;

    button{
      margin-top: 10px;
      padding: 10px 40px;
      background-color: black;
      text-align: center;
      border-radius: 100px;
      color: white;
      letter-spacing: 1.5px;
      font-family: "Proxima Nova", Georgia, sans-serif;
    }
  }

  &__HoverTip{
    &__item{

    }
  }
  &__HoverMenu{
    &__title{
      text-align:center;
      font-size: 15px;
      font-weight:700;
      background-color: darkslategrey;
      color: white;
    }
    &__item{
      &.selected{
        background-color: bisque;
      }
      &:hover{
        cursor: pointer;
        background-color:lightgray;
      }
    }
  }
}
g{

  &.move{
    .tooth{
      stroke:slateblue;
      fill:slateblue;
    }
    .tnum{
      fill:white;
    }
  }

  &:hover{
    cursor:pointer;
    .tooth{
      fill:lightgray !important;
    }
  }

  &.missing{
      .tooth{
        stroke:lightgray;
        fill:white;
      }

      .tnum{
        fill:lightgray;
      }
  }

  &.implant{
      .tooth{
        stroke:lightslategray;
        fill: lightslategray;
      }

      .tnum{
        fill:white;
      }
  }

  &.crown{
      .tooth{
        stroke:lightslategray;
        fill: lightslategray;
      }

      .tnum{
        fill:white;
      }
  }

  &.bridge{
      .tooth{
        stroke:lightslategray;
        fill: lightslategray;
      }

      .tnum{
        fill:white;
      }
  }

  &.inlay{
      .tooth{
        stroke:lightslategray;
        fill: lightslategray;
      }

      .tnum{
        fill:white;
      }
  }

  &.onlay{
      .tooth{
        stroke:lightslategray;
        fill: lightslategray;
      }

      .tnum{
        fill:white;
      }
  }
}

.tooth {
  stroke: black;
  fill: white;

}

line{
  stroke:black;
}
