.Login{

  background-color: #F7F9FA;
  height:100vh;

  &__Form{
    background-color:white;
    padding: 100px 35px 0px 35px;
    height: 500px;
    width:300px;

    .Input_Text{
      border: solid 1px #e6edf0;
      box-shadow: none;
      letter-spacing: .8px;

      &::placeholder{
        color: #7a8f99;
        letter-spacing: .8px;
        font-family: 'Proxima Nova';
      }
    }

    &__Logo{
      font-size:20px;
      color:gray;
      text-align:center;
      letter-spacing: .3em;
    }

    &__Header{
      margin-top:10px;
      padding:15px;
      font-size:16px;
      text-align:center;
      padding-bottom:0;
      color: #12171a;
    }

    &__FormError{
      height:30px;

      .Error{
        font-size:14px;
      }
    }

    &__Help{
      text-align:center;
    }

    &__forgotText{
      margin-top: 20px;
      letter-spacing: 0.8px;
      a{
        span{
          text-decoration: none;
          color: #405159;
        }
      }

    }

    &__registerText{
      margin-top: 115px;

      &__LineOne{
        display:block;
        letter-spacing: 1px;
        font-size: 15px;
        padding-bottom: 5px;
      }

      &__LineTwo{
        margin-top:5px;
        letter-spacing: 0.9px;
        font-size: 13px;

        span{
          text-decoration: none;
          color: #0661ff;
        }
      }
    }

    &__FormButton{
      display:flex;
      justify-content: center;
      margin-top: 5px;
    }

    .Button{
      width: 200px;
      padding: 15px 20px;
      margin: 0;
      font-size:14px;
    }
  }
}
