@charset "UTF-8";
/* scheduling.style */
@import url(./fonts.css);
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0; }

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled].rbc-btn {
  cursor: not-allowed; }

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch; }

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit; }

.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rbc-rtl {
  direction: rtl; }

.rbc-off-range {
  color: #b3b3b3; }

.rbc-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0; }

.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none; }

.rbc-row-content {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 4; }

.rbc-today {
  background-color: #eaf6ff; }

.rbc-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px; }

.rbc-toolbar .rbc-toolbar-label {
  width: 100%;
  padding: 0 10px;
  text-align: center; }

.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: .375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap; }

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad; }

.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c; }

.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad; }

.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad; }

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap; }

.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0; }

.rbc-btn-group button + button {
  margin-left: -1px; }

.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px; }

.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px; }

.rbc-event {
  cursor: pointer;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff; }

.rbc-event.rbc-selected {
  background-color: #265985; }

.rbc-event-label {
  font-size: 80%; }

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row; }

.rbc-row-segment {
  padding: 0 1px 1px 1px; }

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1); }

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap; }

.rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%; }

.rbc-month-view .rbc-header {
  border-bottom: 1px solid #DDD; }

.rbc-month-view .rbc-header + .rbc-header {
  border-left: 1px solid #DDD; }

.rbc-rtl .rbc-month-view .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #DDD; }

.rbc-month-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row; }

.rbc-month-row {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  overflow: hidden;
  height: 100%; }

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #DDD; }

.rbc-date-cell {
  padding-right: 5px;
  text-align: right; }

.rbc-date-cell.rbc-now {
  font-weight: bold; }

.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none; }

.rbc-row-bg {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  overflow: hidden; }

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #DDD; }

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #DDD; }

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px; }

.rbc-overlay > * + * {
  margin-top: 1px; }

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px; }

.rbc-agenda-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  overflow: auto; }

.rbc-agenda-view table {
  width: 100%;
  border: 1px solid #DDD; }

.rbc-agenda-view table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top; }

.rbc-agenda-view table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase; }

.rbc-agenda-view table tbody > tr > td + td {
  border-left: 1px solid #DDD; }

.rbc-rtl .rbc-agenda-view table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #DDD; }

.rbc-agenda-view table tbody > tr + tr {
  border-top: 1px solid #DDD; }

.rbc-agenda-view table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #DDD; }

.rbc-rtl .rbc-agenda-view table thead > tr > th {
  text-align: right; }

.rbc-agenda-time-cell {
  text-transform: lowercase; }

.rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' »'; }

.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '« '; }

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap; }

.rbc-agenda-event-cell {
  width: 100%; }

.rbc-time-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%; }

.rbc-time-column .rbc-timeslot-group {
  -ms-flex: 1;
  flex: 1; }

.rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap; }

.rbc-time-gutter,
.rbc-header-gutter {
  -ms-flex: none;
  flex: none; }

.rbc-label {
  padding: 0 5px; }

.rbc-day-slot {
  position: relative; }

.rbc-day-slot .rbc-event {
  border: 1px solid #265985;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow: hidden; }

.rbc-day-slot .rbc-event-label {
  -ms-flex: none;
  flex: none;
  padding-right: 5px;
  width: auto; }

.rbc-day-slot .rbc-event-content {
  width: 100%;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em; }

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7; }

.rbc-time-slot {
  -ms-flex: 1 0 0px;
  flex: 1 0 0; }

.rbc-time-slot.rbc-now {
  font-weight: bold; }

.rbc-day-header {
  text-align: center; }

.rbc-day-slot .rbc-event {
  position: absolute;
  z-index: 2; }

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  padding: 3px; }

.rbc-time-view {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0; }

.rbc-time-view .rbc-time-gutter {
  white-space: nowrap; }

.rbc-time-view .rbc-allday-cell {
  width: 100%;
  position: relative; }

.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4; }

.rbc-time-view .rbc-row {
  min-height: 20px; }

.rbc-time-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-direction: column;
  flex-direction: column; }

.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #DDD; }

.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #DDD; }

.rbc-time-header > .rbc-row > * + * {
  border-left: 1px solid #DDD; }

.rbc-rtl .rbc-time-header > .rbc-row > * + * {
  border-left-width: 0;
  border-right: 1px solid #DDD; }

.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #DDD; }

.rbc-time-header .rbc-gutter-cell {
  -ms-flex: none;
  flex: none; }

.rbc-time-header > .rbc-gutter-cell + * {
  width: 100%; }

.rbc-time-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative; }

.rbc-time-content > .rbc-time-gutter {
  -ms-flex: none;
  flex: none; }

.rbc-time-content > * + * > * {
  border-left: 1px solid #DDD; }

.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #DDD; }

.rbc-time-content > .rbc-day-slot {
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.rbc-current-time-indicator {
  position: absolute;
  z-index: 1;
  left: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none; }

.rbc-current-time-indicator::before {
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  content: ' ';
  background-color: #74ad31;
  border-radius: 50%;
  width: 8px;
  height: 8px; }

.rbc-rtl .rbc-current-time-indicator::before {
  left: 0;
  right: -3px; }

html {
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit; }

p {
  margin: 0; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

button {
  border: 0;
  border-radius: 0;
  padding: 0;
  background-color: inherit;
  cursor: pointer; }

input {
  border: 0; }

input:focus,
button:focus {
  outline: none; }

.Scheduling {
  display: grid;
  grid-template-columns: 90px auto 22.5%;
  grid-template-rows: 72px 260px 100px 630px; }

.Scheduling .Header {
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
  z-index: 1; }

.Scheduling .Sidebar {
  grid-row: 2 / span 3;
  grid-column: 1 / span 1; }

.TopSection {
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;
  padding: 42px 33px 0;
  background-color: #FCFEFF;
  box-shadow: 0 4px 15px -2px whitesmoke; }
  .TopSection__welcome-msg {
    float: left;
    font-size: 19px; }
  .TopSection__calendar-view-options {
    float: right;
    width: 279px;
    height: 48px;
    border-radius: 20px;
    background-color: #F0F3F5;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .TopSection__calendar-view-option {
    display: inline-block;
    padding: 0 20px;
    font-size: 15px;
    font-weight: bolder;
    letter-spacing: 1px;
    background: transparent; }
    .TopSection__calendar-view-option--active {
      height: 40px;
      margin-top: 1px;
      background: white;
      border-radius: 20px; }
  .TopSection__today-label {
    clear: both;
    font-size: 15px;
    font-weight: bolder;
    letter-spacing: 1px;
    margin-bottom: 8px; }
  .TopSection__today-date {
    font-size: 28px;
    font-weight: bolder;
    margin-bottom: 40px; }
    .TopSection__today-date .fa-chevron-down {
      font-size: 13px;
      line-height: 32px;
      margin-left: 50px; }
  .TopSection__dentist-list-wrapper {
    display: flex;
    align-items: center; }
  .TopSection__dentist-list-label {
    display: inline-block;
    margin-right: 37px;
    font-size: 15px;
    font-weight: bolder;
    letter-spacing: 1px; }
  .TopSection__dentist-list {
    display: inline-block;
    margin-right: 90px; }
  .TopSection__dentist-list-item {
    float: left;
    width: 196px;
    height: 32px;
    border-radius: 15px;
    margin-right: 24px;
    padding-right: 15px;
    font-size: 14px;
    line-height: 32px; }
    .TopSection__dentist-list-item .fa-check {
      color: white;
      font-size: 13px;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      line-height: 34px;
      text-align: center;
      margin-right: 12px; }
    .TopSection__dentist-list-item .fa-check:hover {
      cursor: pointer; }
    .TopSection__dentist-list-item--blue {
      background-color: #D4DFFD; }
      .TopSection__dentist-list-item--blue .fa-check {
        background-color: #2D44FF; }
    .TopSection__dentist-list-item--orange {
      background-color: #FDEACF; }
      .TopSection__dentist-list-item--orange .fa-check {
        background-color: #FF994E; }
    .TopSection__dentist-list-item--gray {
      background-color: #F5F5F5; }
      .TopSection__dentist-list-item--gray .fa-check {
        background-color: #DADADA; }
    .TopSection__dentist-list-item--1 {
      background-color: #D4DFFD; }
      .TopSection__dentist-list-item--1 .fa-check {
        background-color: #2D44FF; }
    .TopSection__dentist-list-item--2 {
      background-color: #FDEACF; }
      .TopSection__dentist-list-item--2 .fa-check {
        background-color: #FF994E; }
    .TopSection__dentist-list-item--3 {
      background-color: #F5F5F5; }
      .TopSection__dentist-list-item--3 .fa-check {
        background-color: #DADADA; }
  .TopSection__dentist-list-add-button {
    display: inline-block;
    font-size: 14px;
    padding: 5px 20px;
    color: #76909A;
    transition: border .1s;
    border-radius: 2px; }
    .TopSection__dentist-list-add-button:hover {
      border: 1px solid #76909A; }

.WeekCalendar {
  grid-row: 3 / span 2;
  grid-column: 2 / span 1;
  position: relative; }
  .WeekCalendar__big-calendar {
    background-color: #F6F9FA;
    padding: 28px 0 0 24px; }
    .WeekCalendar__big-calendar .rbc-time-view {
      border: 0; }
    .WeekCalendar__big-calendar .rbc-time-header {
      border: 0; }
      .WeekCalendar__big-calendar .rbc-time-header .rbc-header-gutter {
        margin-right: 15px; }
      .WeekCalendar__big-calendar .rbc-time-header .rbc-row {
        border: 0; }
      .WeekCalendar__big-calendar .rbc-time-header .rbc-row:nth-child(2) {
        display: none; }
      .WeekCalendar__big-calendar .rbc-time-header .rbc-header {
        border: 0;
        font-size: 17px;
        text-align: left;
        padding-bottom: 40px;
        /*word-spacing: 60px;*/
        font-weight: normal;
        padding-left: 40px;
        padding-right: 40px; }
        .WeekCalendar__big-calendar .rbc-time-header .rbc-header.rbc-today {
          font-weight: bolder; }
        .WeekCalendar__big-calendar .rbc-time-header .rbc-header .rbc-header-today-view {
          text-align: center; }
        .WeekCalendar__big-calendar .rbc-time-header .rbc-header .rbc-header-date {
          float: right; }
    .WeekCalendar__big-calendar .rbc-time-content {
      border: 0;
      /*.rbc-event:hover{
        z-index:10000;
        border-color:transparent;
      }*/ }
      .WeekCalendar__big-calendar .rbc-time-content .rbc-current-time-indicator {
        background-color: #367fff;
        left: 33px !important;
        z-index: 3; }
        .WeekCalendar__big-calendar .rbc-time-content .rbc-current-time-indicator::before {
          background-color: #367fff; }
      .WeekCalendar__big-calendar .rbc-time-content .rbc-time-gutter {
        margin-right: 15px; }
        .WeekCalendar__big-calendar .rbc-time-content .rbc-time-gutter .rbc-time-slot:not(.rbc-label)::after {
          content: "\2022";
          display: inline-block;
          padding: 0 0 0 32px;
          font-size: 12px;
          position: relative;
          bottom: 12px; }
        .WeekCalendar__big-calendar .rbc-time-content .rbc-time-gutter .rbc-timeslot-group {
          border: 0; }
      .WeekCalendar__big-calendar .rbc-time-content .rbc-timeslot-group {
        height: 188px;
        border: 0;
        border-top: 2px solid #EEF1F4; }
      .WeekCalendar__big-calendar .rbc-time-content .rbc-time-slot.rbc-label {
        font-size: 10px;
        font-weight: bolder; }
      .WeekCalendar__big-calendar .rbc-time-content .rbc-event {
        border: 0;
        color: black;
        padding: 12px 14px;
        border-bottom: 8px solid #F5F9FA;
        border-left: 30px solid #F5F9FA;
        border-right: 30px solid #F5F9FA;
        padding-right: 0px; }
        .WeekCalendar__big-calendar .rbc-time-content .rbc-event .rbc-event-label {
          font-size: 12px;
          order: 2;
          flex-grow: 0; }
        .WeekCalendar__big-calendar .rbc-time-content .rbc-event .rbc-event-content {
          font-size: 14px;
          flex-grow: 0;
          margin-bottom: 10px;
          overflow: hidden;
          font-weight: 600; }
      .WeekCalendar__big-calendar .rbc-time-content .blue {
        background-color: #D4DFFD; }
      .WeekCalendar__big-calendar .rbc-time-content .orange {
        background-color: #FDEACF; }
      .WeekCalendar__big-calendar .rbc-time-content .gray {
        background-color: #F5F5F5; }
      .WeekCalendar__big-calendar .rbc-time-content .rbc-slot-selection {
        background-color: #3C70FF;
        box-shadow: 0 0 10px #3C70FF;
        color: white;
        font-size: 12px;
        width: 100%;
        padding-right: -15px;
        border: none; }
  .WeekCalendar__add-appointment-button {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    right: 25px;
    bottom: 35px;
    width: 256px;
    height: 56px;
    background-color: #1F3238;
    border-radius: 30px;
    padding: 0 10px 0 20px;
    font-size: 14px;
    line-height: 56px;
    color: white;
    z-index: 3;
    box-shadow: 0 0 10px #1F3238; }
  .WeekCalendar__add-appointment-button-text {
    flex-grow: 1;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600; }
  .WeekCalendar__add-appointment-button-plus {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #0F0D12;
    border-radius: 50%;
    line-height: 40px;
    position: relative;
    /*left: 18px;*/ }

.rbc-today {
  background-color: #F5F9FA; }

.MonthCalendar {
  grid-row: 2 / span 2;
  grid-column: 3 / span 1;
  border-bottom: 10px solid #F3F6F8;
  box-shadow: -1px 0 10px whitesmoke; }
  .MonthCalendar .react-calendar {
    width: 100%;
    height: 100%;
    border: 0; }
    .MonthCalendar .react-calendar__tile {
      padding: 0; }
      .MonthCalendar .react-calendar__tile--today {
        background: #f2f4f5; }
        .MonthCalendar .react-calendar__tile--today:not([disabled]):hover {
          background: #f2f4f5; }
      .MonthCalendar .react-calendar__tile--active {
        background: black; }
        .MonthCalendar .react-calendar__tile--active:not([disabled]):hover {
          background: black; }
        .MonthCalendar .react-calendar__tile--active.react-calendar__month-view__days__day--weekend {
          color: white; }
    .MonthCalendar .react-calendar__navigation {
      height: 84px;
      justify-content: center;
      margin-bottom: 0; }
      .MonthCalendar .react-calendar__navigation button[disabled] {
        background-color: rgba(0, 0, 0, 0); }
      .MonthCalendar .react-calendar__navigation__arrow {
        font-size: 21px;
        padding-bottom: 3px; }
        .MonthCalendar .react-calendar__navigation__arrow button {
          min-width: 64px; }
      .MonthCalendar .react-calendar__navigation__label {
        text-transform: uppercase;
        flex-grow: 0 !important;
        font-weight: bold; }
      .MonthCalendar .react-calendar__navigation__prev2-button {
        display: none; }
      .MonthCalendar .react-calendar__navigation__next2-button {
        display: none; }
    .MonthCalendar .react-calendar__month-view {
      padding: 0 11px; }
      .MonthCalendar .react-calendar__month-view__weekdays__weekday {
        padding: 0;
        margin-bottom: 2px;
        font-weight: normal;
        font-size: 0; }
        .MonthCalendar .react-calendar__month-view__weekdays__weekday::first-letter {
          font-size: 12px; }
      .MonthCalendar .react-calendar__month-view__days > div {
        justify-content: center; }
      .MonthCalendar .react-calendar__month-view__days__day {
        width: 32px;
        height: 32px;
        flex-grow: 0 !important;
        border-radius: 50%;
        text-align: center;
        margin-bottom: 2px;
        font-size: 14px; }
        .MonthCalendar .react-calendar__month-view__days__day--weekend {
          color: black; }
        .MonthCalendar .react-calendar__month-view__days__day--neighboringMonth {
          color: #E1E1E1; }

.ActivityFeed {
  grid-row: 4 / span 1;
  grid-column: 3 / span 1; }
  .ActivityFeed__heading {
    height: 52px;
    line-height: 52px;
    font-size: 15px;
    font-weight: bolder;
    padding-left: 25px; }
  .ActivityFeed__feed-wrapper {
    background-color: #FAFAFA;
    overflow-y: auto;
    height: calc(100% - 52px); }

.ActivityItem {
  padding: 20px 14px 13px 14px;
  clear: both;
  overflow: hidden; }
  .ActivityItem__img {
    display: inline-block;
    margin-right: 6px;
    float: left; }
  .ActivityItem__text-wrapper {
    float: left; }
  .ActivityItem__user-name {
    font-size: 14px;
    margin-bottom: 5px; }
  .ActivityItem__description {
    font-size: 12px; }
  .ActivityItem__time-ago {
    font-size: 12px;
    color: #99999A;
    float: right;
    padding-top: 20px; }
  .ActivityItem__photo-list {
    clear: both;
    padding-top: 15px; }
  .ActivityItem__photo {
    display: inline-block; }
