.MessageAttachment{

    height: 170px;
    width: 160px;
    display: flex;
    flex-direction: column;
    margin-right:30px;
    z-index: 2;
    position:relative;

    &__previews{
      padding-top:20px;
    }

    &.dz-processing {
      .Loader{
        display:block;
      }

      .MessageAttachment__iconContainer__removeIcon{
        display:none;
      }

    }
    &.dz-complete  {
      .Loader{
        display:none;
      }

      .MessageAttachment__iconContainer__removeIcon{
        display:block;
      }
    }

    &:not(.dz-processing) {
    }

    .Loader{
      position:absolute;
      top:0;
      left:0;
    }

    &__edit{
      &__attachment{
        height: 170px;
        position: relative;
        width:100%;
        left: 0;
        top: 0;

        .dropzone {
          height: 100%;
          display:flex;
          justify-content: center;
          align-items: center;

          .dz-preview{
            height: 100px;
            width: 100px;
            margin: 20px 40px;

            .dz-image{
              height:100px;
              width:100px;
            }
          }
        }
      }
    }

    a {
      color: inherit;

      &:hover{
        text-decoration: none;
      }
    }

    &__iconContainer{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 130px;
      border: 1px solid #bbbec5;
      background-color: #ffffff;
      position: relative;

      &__removeIcon{
        position:absolute;
        width: 100%;
        top: 0;
        right: 0;

        svg{
          float: right;
          margin-top: 3px;
          margin-right: 3px;

          &:hover{
            cursor:pointer;
          }
        }
      }

      &__icon{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;

        svg {
          height: 100px;
          width: 40%;
          color: #7a8f99;
        }
      }
    }

    &__nameContainer{
      width:100%;
      text-align:center;
      border: 1px solid #bbbec5;
      border-top:none;
      background-color:white;
      padding: 10px 15px;
      color: #12171a;
      position: relative;

      &__fileName{
        overflow-x:hidden;
        white-space:nowrap;
        width:100%;
        font-size: 15px;
        letter-spacing: 1px;
        font-family: 'Proxima Nova';
        color:  #12171a;
      }

    }
}
