.OverarchingDialog {

    &-general{
      .OverarchingDialog__content{
        padding-bottom: 0px;
      }
    }

    &-loading{
      .OverarchingDialog__content{
        padding-bottom: 20px;
      }
    }

    &.popup-dialog{
      background: #f5f5f5;
    }

    .popup-dialog-header{
      background: #253238;
      text-align:center;
    }

    &__content{
      color: #12171a;
    }

    &__progress {
      text-align: center;
      padding-bottom: 30px;
    }

    .popup-dialog-footer{
      padding-top: 0;
    }
}
