.LoadingDialog {

    &.popup-dialog{
      background: #f5f5f5;
    }

    .popup-dialog-header{
      background: #253238;
      text-align:center;
    }

    &__content{
      padding-bottom: 40px;
      color: #12171a;
    }

    &__progress {
      text-align: center;
      padding-bottom: 30px;
    }
}
