.NoteForm{

  padding: 0 34px 22px 30px;

  &__Inputs{

    .Error__Label{
      height:15px;
      font-size:12px;
    }
  }

  &__Actions{
    display: flex;
    justify-content: flex-end;

    button{
      margin-left:10px;
    }
  }


}
