/* forms.style */

.bdTimePicker > .rc-time-picker-input{
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    border: solid 1px #e6edf0;
    box-shadow: none;
    border-radius: 3px;
    color: #12171a;
    height: 40px;
}

.bdDateTimePicker{
  &> .react-datetime-picker{
    &__wrapper{
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      min-width: 280px;
      font-size: 14px;
      line-height: 1.42857143;
      background-color: #fff;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      border: solid 1px #e6edf0;
      box-shadow: none;
      border-radius: 3px;
      color: #12171a!important;
      height: 40px;
    }
  }

  &.react-datetime-picker--disabled{
    &> .react-datetime-picker__wrapper{
      background-color: #f0f0f0;

      input:disabled{
        background-color:#f0f0f0;
      }
    }
  }
}

.bdDateTimePicker > .react-datetime-picker__wrapper{

  width: 100%;
  height: 34px;
  padding: 6px 12px;
  min-width: 280px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: #fff;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  border: solid 1px #e6edf0;
  box-shadow: none;
  border-radius: 3px;
  color: #12171a!important;
  height: 40px;
}


.ScheduleAddDentistForm{
	width: 500px;
	height:300px;
}

.EditPatientForm{
	width: 700px;
	height:820px;
}

.CreateUserAccountForm{
	width: 700px;
	height:650px;
}

.InsuranceForm{
	width: 700px;
	height:710px;
}

.QuickPatientForm{
	width: 700px;
	height:440px;
}

.AppointmentForm{
	width: 700px;
	/*height:690px;*/
}

.ImageUploadForm{
	width:872px;
	height:810px;
}

.Overlay{
	z-index:5;
	background-color:rgba(0, 0, 0, 0.54);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Content{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-height: calc(100% - 100px);
}

.Content:focus {outline:0;}

.DialogFormContent{
  padding-bottom:0;
}

.Buttons{
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;

  padding: 0 54px;
}

.Error {
  color: #d0021b;
}

.Error__Label {
  height: 30px;
  padding: 5px 5px;
  overflow: scroll;
}

.Select-control {
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.Input_Error {
  .Select-control {
    border-color: #d0021b;
  }
}

.Input_Error {
  border-color: #d0021b;
}

.Input_Error:focus {
  border-color: #d0021b;
}

.Button:disabled {
  border-color: #999999;
}

.Button {
  font-size:13px;
  margin-left:10px;
  letter-spacing: .9px;
  text-align: center;
  line-height: 19px;
  border-radius: 100px;
  width:100px;

  &__Cancel{
    color: #12171A;
    border: 1px solid #273238;
    padding: 5px 0;
  }

  &__Save{
    color: #FFFFFF;
    background: #273238;
    padding: 5px 0;
  }

  &__Stop{
    border: 1px solid #367FFF;
    color: #367FFF;
    padding: 5px 0;
  }

  &__Start{
    color: #FFFFFF;
    background: #367FFF;
    padding: 5px 0;
  }
}

.ModalForm{

	z-index:5;

	&__Container{
		font-family: 'Proxima Nova', Georgia, sans-serif;
		background: #F7F9FA;
		border: 1px solid #EDEDED;
		border-radius: 4px 4px 0 0;
		/*box-shadow: 1px 3px 20px 20px rgba(239,239,239,0.50);*/
		box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
		text-align:left;
		padding: 50px 70px 60px 70px;
	}

	&__Header{
		font-size:18px;
		color: #12171A;
		letter-spacing: 0.83px;
		line-height: 26px;

	}

	&__SubHeader{
		margin-top:5px;
		font-size:12px;
		color: #4A4A4A;
		letter-spacing: 0.83px;
		line-height: 19px;
	}

	&__Buttons{
		margin-top: 70px;
		display: flex;
		justify-content: flex-end;

		.Button{
			font-size:13px;
			letter-spacing: .9px;
			text-align:center;
			line-height: 19px;
			border-radius: 100px;


			&__Cancel{
				color: #12171A;
				border: 1px solid #273238;
				padding: 5px 22px 5px 23px;
			}

			&__Save{
				color: #FFFFFF;
				background: #273238;
				padding: 5px 33px 5px 32px;
				margin-left:10px;
			}

			&__Stop{
				border: 1px solid #367FFF;
				color: #367FFF;
			}

			&__Start{
				color: #FFFFFF;
				background: #367FFF;
			}
		}


	}

	&__Inputs {
		margin-top: 35px;
		display: flex;

		&.One{
			justify-content: space-evenly;
		}

		&.Two{
			justify-content: space-between;
		}

		&.Three{
			justify-content: space-between;
		}

		&.AddaPatient{
			justify-content: flex-start;
		}


		.Input{

			&.One{
				width: 100%;
			}

			&.Two{
				width: 45%;
			}

			&.Three{
				width: 30%;
			}
			&__Label{
				font-size:14px;
				color: #12171A;
				letter-spacing: 0.78px;
				line-height: 26px;
			}

			&__Text{
				background: #FFFFFF;
				border: 1px solid #C7D3D9;
				border-radius: 3px;
			}

			&__Radio{
				font-size: 13px;
				color: #9B9B9B;
				letter-spacing: 0.9px;
				line-height:19px;
				text-align:center;
				background: #FFFFFF;
				border: 1px solid #C7D3D9;
				border-radius: 3px;

				--selected{
					color: #000000;
					border-color: #273238;
				}
			}

		}
	}

}

.VideoControllerContainer {
  padding: 0 30px;

  &__Label {
    //padding-left:15px
  }
}

@keyframes blinker {
  from { opacity: 1; }
  to { opacity: 0; }
}

.VideoController {
  display:flex;
  padding: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;

  &__VideoPlayer {
    width: 600px;

    &.dropzone {
      margin: 5px;
      height: 400px;
      width: 600px;
    }

    &__Recording {
      position: absolute;
      z-index: 1;
      top: 0;

      &-Icon{
        margin-top: 8px;
        margin-left: 5px;
        animation: blinker .8s cubic-bezier(.5, 0, 1, 1) infinite alternate;
      }
    }

    &__VideoScreen {
      width:100%;
      border-bottom: 1px solid #ccc;
      padding: 5px 5px 0 5px;
      min-height: 300px;
    }

    &__VideoButtons {
      width: 100%;

      &__Buttons {
        justify-content: flex-start;

        .Button {
          margin-left:15px;
          margin-bottom:10px;
          margin-top:10px;
        }
      }
    }
  }

  &__VideoList {
    width:300px;
    height:410px;
    border-left: 1px solid #ccc;
    overflow-y: scroll;
		display:flex;
		flex-direction:column;

    &.dropzone{
      border:none;
      border-left: 1px dotted	 #ccc;
    }

    &__Video {
      padding: 5px;
    }

		.dz-preview{
			align-self: center;
			flex-shrink:0;
			margin-bottom:10px;

			.dz-image{

			}

			.dz-details{

			}

			.dz-progress{

			}

			.dz-error-message{

			}

			.dz-success-mark{

			}

		}


  }
}

.filepicker {
    font-family: sans-serif;
}

div.filepicker {
    text-align: center;
    padding: 5px;
    background-color: #E1E1E1;
    border-radius: 5px;
    min-height: 60px;
    border: 2px dashed #C7C7C7;
}

/* Icon */
.filepicker-file-icon{
    position: relative;

    display: inline-block;

    margin: 1.5em 0 2.5em 0;
    padding-left: 45px;

    color: black;
}

.filepicker-file-icon::before{
    position: absolute;
    top: -7px;
    left: 0;

    width: 29px;
    height: 34px;

    content: '';

    border: solid 2px #7F7F7F;
    border-radius: 2px;
}

.filepicker-file-icon::after{
    font-size: 11px;
    line-height: 1.3;

    position: absolute;
    top: 9px;
    left: -4px;

    padding: 0 2px;

    content: 'file';
    content: attr(data-filetype);
    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #fff;
    background-color: #000;
}

.filepicker-file-icon{
		.fileCorner{
	    position: absolute;
	    top: -7px;
	    left: 22px;

	    width: 0;
	    height: 0;

	    border-width: 11px 0 0 11px;
	    border-style: solid;
	    border-color: white transparent transparent #920035;
		}
}
