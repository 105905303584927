/* patient.style */

@import '~https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  display: block;
}

.mirrorLabel {
  font-size:16pxs
}

button {
  border: 0;
  border-radius: 0;
  padding: 0;
  background-color: inherit;
  cursor: pointer;
}

input {
  border: 0;
}

input:focus,
button:focus {
  outline: none;
}

td {
  padding: 0;
}

h1,
h2 {
  margin: 0;
}

video.flipped {
  -o-transform : scaleX(-1);
  -moz-transform : scaleX(-1);
  -webkit-transform : scaleX(-1);
  -ms-transform: scaleX(-1);
  transform : scaleX(-1);
}

.MirrorLabel {

}

.Patient {
  display: grid;
  grid-template-columns: 90px auto [end];
  grid-template-rows: 72px auto [end];

  a{
    color:inherit;
    text-decoration:none;

    &:hover{
      color:inherit;
      text-decoration: none;
    }
  }
}

.Patient .Header {
  grid-row: 1 / span 1;
  grid-column: 1 / end;
}

.Patient .Sidebar {
  grid-row: 2 / end;
  grid-column: 1 / span 1;
}

.Patient .ContentWrapper {
  grid-row: 2 / end;
  grid-column: 2 / end;
  background-color: #ECEEEF;
}


.LedgerForm {
  width:500px;
}

.Tabs {
  height: 90px;
  background-color: #F7F9FA;
  display: flex;
  justify-content: space-between;
  padding: 17px 80px 0 23px;
}

.Tab {
  width: 32%;
  background-color: #E8EEFA;
  padding: 10px 28px 0 33px;
  letter-spacing: 1px;

  &--hidden {
    visibility: hidden;
  }

  &--active {
    background: white;
    position: relative;

    .Tab__time {
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 15px;
        top: 25px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #3C70FF;
      }
    }

    .Tab__patient-name {
      display: none;
    }
  }

  &__time {
    &:hover{
      cursor:pointer;
    }
    display: inline-block;
    font-size: 17px;
    font-weight:bolder;
    margin-right: 30px;
    margin-bottom: 3px;
  }

  &__date {
    font-size: 12px;
    font-weight:bolder;
  }

  &__patient-name {
    font-size: 14px;
  }
}

.TabContent {
  padding: 58px 0 0 3.75vw;
  background-color: white;

  &__left-section {
    overflow: hidden;
    width: 59vw;
    border-right: 2px solid #F2F6F7;
    float: left;
  }

  &__patient-summary-heading {
    font-size: 15px;
    font-family: 'Proxima Nova Bold';
    /*font-weight:bolder;*/
    letter-spacing: 1px;
    color: #3D525A;
    padding-left: 197px;
    margin-bottom: 26px;
  }

  &__patient-img {
    margin-right: 68px;
    float: left;

    div{
      border-radius:10px !important;
    }
  }

  &__patient-summary-middle-wrapper {
    float: left;
    width: 300px;
    margin-right:15px;
  }

  &__patient-name {
    font-size: 24px;
    font-weight: bolder;
    margin-bottom: 18px;
  }

  &__phone-number {
    font-size: 14px;
    margin-bottom: 12px;
  }

  &__address {
    font-size: 14px;
    margin-bottom: 12px;
  }

  &__email {
    font-size: 14px;
    margin-bottom: 12px;
  }

  &__patient-details-table {
    float: left;
    font-size: 13px;
    width: 290px;
    margin-bottom: 38px;
  }

  &__patient-details-table-cell {
    height: 25px;
    width: 50%;
    vertical-align: top;

    &__label{
      font-family: 'Proxima Nova Bold';
    }
  }

  &__more-details-button {
    clear: both;
    float: right;
    font-size: 14px;
    font-weight: bolder;
    letter-spacing: 1px;
    color: #76909A;
    margin-right: 44px;
  }

  &__right-section {
    padding-left: 1.53vw;
    width: 29.5vw;
    float: left;
  }

  &__medical-history-heading {
    font-size: 15px;
    font-weight:bolder;
    letter-spacing: 1px;
    color: #3D525A;
    margin-bottom: 27px;
  }

  &__add-history-button {
    float: right;
    color: #76909A;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 14px;
  }

  &__contact-button {
    margin: 86px auto 0;
    display: block;
    width: 215px;
    height: 56px;
    background-color: #253239;
    border-radius: 30px;
    padding: 0 20px;
    font-size: 14px;
    font-weight:bolder;
    line-height: 56px;
    color: white;
    box-shadow: 0 0 10px #1F3238;
    letter-spacing: 1px;
  }

  &__contact-button-plus {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #11171A;
    border-radius: 50%;
    line-height: 40px;
    position: relative;
    left: 40px;
    font-size: 20px;
  }

  &__page-selector {
    clear: both;
    height: 75px;
    margin-top: 9px;
  }

  &__page-button {
    font-size: 17px;
    padding: 0 2px;
    margin-right: 65px;
    font-weight:bolder;

    &:hover {
      padding-bottom: 14px;
      border-bottom: 4px solid #253239;
    }

    &--active {
      padding-bottom: 14px;
      border-bottom: 4px solid #253239;
    }
  }
}

.MedicalHistoryItem {
  height: 54px;
  border: 1px solid #E5EDF0;
  border-radius: 3px;
  background-color: #F7F9FA;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 8px;

  &__name {
    font-size: 14px;
  }

  &__dentist {
    font-size: 12px;
    margin-left: 35px;
  }

  &__date {
    font-size: 12px;
    color: #7690A2;
  }
}

.PageContent {
  margin: 22px 1.8vw 24px 1.67vw;
  background-color: white;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  &__Imaging{
    background-color: #ECEEEF;

    .ImageSetUpload{
      padding-bottom:50px;
    }

  }

  &__left-section {
    padding: 24px 5.56vw 0 3.47vw;
    width: 66.66vw;
    background-color: #FDFEFF;
    flex:8;
  }

  &__teeth-diagram {
    margin-left: 80px;
  }

  &__descriptions-heading {
    font-size: 19px;
    font-weight:bolder;
    padding-bottom: 12px;
    border-bottom: 2px solid #EAEFF0;
    margin-bottom: 19px;
  }

  &__descriptions-body {
    clear: both;
    font-size: 14px;
    line-height: 1.4;
    padding-bottom: 50px;
  }

  &__Billing {
    padding: 24px;
    margin-bottom:60px;
  }

  &__Insurance {
    padding: 24px;
    margin-bottom:60px;
  }

  &__Reports {
    padding: 35px;
    margin-bottom:60px;
    flex-direction: column;

    .noResults{
      padding-top: 15px;
    }

    &__header{
      display:flex;
      justify-content: space-between;
      margin-bottom: 0;

      &__archived{
        margin-top:30px;
        padding-bottom: 15px;
      }

      &__title{
        font-family: 'Proxima Nova Semibold';
        font-size: 15px;
        letter-spacing: 1px;
        span{
          margin-left: 15px;
          font-style: italic;
          color: rgba(51, 51, 51, 0.8);
        }

        a{
          display: flex;
          align-items: center;

          svg {
            font-size:14px;
          }
        }
      }
    }

    &__newRequestBar{
      display:flex;
      justify-content: flex-end;
      margin-bottom: 10px;

      &__requestButton{
        display:flex;
        align-items:center;

        &__text{
          padding-right:15px;
          color: rgb(155,155,155);
        }

        svg{
          height:18px;
          width:20px;
          fill:rgb(155,155,155);
        }
      }
    }
  }
}

.ToothChart {
  &__tooth-column {
    /*cursor: pointer;*/
    fill: #FDFEFF;

    &--highlight {
      .ToothChart__highlighter {
        fill: #367FFF;
        pointer-events: none;
      }

      .ToothChart__tooth-label-circle {
        fill: #367FFF;
      }
    }
  }

  &__tooth-label {
    //pointer-events:all;
    fill:rgb(199, 211, 217);

    &:hover {
      fill:rgb(54, 127, 255);
      //cursor:pointer;
    }
  }

  &__facial-view {
    fill: #FDFEFF;
  }

  &__facial-view-component {
    fill: #FDFEFF;
  }

  &__top-view {
    fill: #FDFEFF;

    &--fill-1 {
      .ToothChart__top-view-buccal{
        fill: black;
      }
    }

    &--fill-2 {
      .ToothChart__top-view-mesial {
        fill: black;
      }
    }

    &--fill-3 {
      .ToothChart__top-view-occlusal {
        fill: black;
      }
    }

    &--fill-4 {
      .ToothChart__top-view-distal {
        fill: black;
      }
    }

    &--fill-5 {
      .ToothChart__top-view-lingual {
        fill: black;
      }
    }

    &--selected-1 {
      .ToothChart__top-view-buccal{
        selected: #3B70FF;
      }
    }

    &--selected-2 {
      .ToothChart__top-view-mesial {
        selected: #3B70FF;
      }
    }

    &--selected-3 {
      .ToothChart__top-view-occlusal {
        selected: #3B70FF;
      }
    }

    &--selected-4 {
      .ToothChart__top-view-distal {
        selected: #3B70FF;
      }
    }

    &--selected-5 {
      .ToothChart__top-view-lingual {
        selected: #3B70FF;
      }
    }
  }

  &__top-view-circle {
    fill: #FDFEFF;
  }

  &__highlighter {
    fill: none;
  }
}

.ConsultRequestContent{

  &.ConsultRequestProgress{
    background-color: #F7F9FA;
    padding: 30px 100px;
    border: 1px solid #E6EDF0;
    border-radius: 5px;
    margin-bottom: 30px;

    .ConsultRequestList__Headers{
      border-bottom: 1px solid #E6EDF0;
      padding-left:0;
    }

    .ConsultRequestProgress__card{
      min-height: 35px;
      padding-left:0;

      &:first-child{
        padding-top:10px;
      }
    }
  }

  .ConsultRequestList__Headers{
    .cell {
      font-family: 'Proxima Nova Bold';
    }
  }

  .cell {
    &.requestHospital {
      flex:3;
    }

    &.requestDentist {
      flex:2;
    }

    &.requestDate {
      flex:2;
    }

    &.requestStatus {
      flex:2;
    }

    &.requestStartDate{
      flex:2;
    }

    &.requestNextVisit{
      flex:2;
    }

    //&.requestStatus{
    //  flex:2;
    //}
  }
}

.PageContentSidebar {
  flex:3;
  width: 23vw;
  float: left;
  background-color: white;
  padding: 0 0 21px;
  position: relative;
  box-shadow: -2px 0 4px whitesmoke;
  border-radius: 4px;
  background-color: #F7F9FA;

  &__heading {
    font-size: 19px;
    padding: 0 34px 22px 30px;
    font-weight: bolder;
  }

  &__number {
    display: inline-block;
    float: right;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    color: white;
    background-color: #3B70FF;
    text-align: center;
    line-height: 33px;
    font-size: 17px;
    font-weight: normal;
    margin-top: -5px;
  }

  &__top-section {
    background-color: #FBFBFB;
    padding: 28px 0 0 26px;
    display: flex;
  }

  &__top-section-left {
    width: 11.11vw;
  }

  &__region-label {
    font-size: 15px;
    font-weight:bolder;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }

  &__region {
    margin-left: 7px;
  }

  &__top-section-right {
    flex-grow: auto;
  }

  &__date-label {
    font-size: 15px;
    font-weight:bolder;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }

  &__date {
    font-size: 13px;
    margin-bottom: 24px;
  }

  &__status-label {
    font-size: 15px;
    font-weight:bolder;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }

  &__status {
    font-size: 13px;
    padding-bottom: 36px;
  }

  &__bottom-section {
    padding: 25px 43px 0 30px;
  }

  &__notes-heading {
    font-size: 15px;
    font-family: 'Proxima Nova Bold';
    letter-spacing: 1px;
    margin-bottom: 40px;
    font-weight:bolder;
    padding: 22px 34px 22px 30px;
    background-color:white;
  }

  &__notes {
    padding: 0 34px 22px 30px;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 15px;

    &-button {
    font-family: 'Proxima Nova Bold';
      float: right;
      color: #76909A;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 12px;
    }
  }

  &__show-hide-button {
    position: absolute;
    left: -25px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 68px;
    width: 25px;
    background-color: #3B70FF;
    color: white;
    font-size: 20px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    z-index: 1;
  }
}

.TagList {
  &--page-content {
    margin-bottom: 38px;
    overflow: hidden;

    & > * {
      float: left;
    }
  }

  &--page-content-sidebar {

    display: flex;
    flex-wrap: wrap;
    margin-bottom:20px;
    & > * {
      margin-bottom: 6px;
    }
  }
}

.Tag {
  border-radius: 14px;
  padding: 0 30px 0 45px;
  display: inline-block;
  text-align: center;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  position: relative;
  margin-right: 20px;
  background-color: #F5F5F5;

  &::before {
    content: "+";
    color: white;
    background-color: #DADADA;
    border-radius: 50%;
    font-size: 20px;
    width: 32px;
    height: 32px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  &--dark {
    background-color: #768F99;
    color: white;

    &::before {
      content: "X";
      font-size: 12px;
      background-color: #526A74;
    }
  }
}

.ImageForm{
  font-family: "Proxima Nova", Georgia, sans-serif;

  &__Header{
    font-size:18px;
    font-family: "Proxima Nova Semibold";
    padding-bottom:20px;
  }

  &__Error{
    &__closeButton{
        padding: 10px 30px;
        background-color: black;
        text-align: center;
        border-radius: 100px;
        color:white;
        letter-spacing:1.5px;
    }
  }

  .ImageSetUpload__ImagesHeader{
    span{
    }
  }

  .ImageSetUpload__form__images__row .SingleImageUploadBox__detail {
    height:150px;
  }


  &__nextButton{
    margin-top:10px;
    padding: 10px 40px;
    width: 180px;
    background-color: black;
    text-align: center;
    border-radius: 100px;
    color:white;
    letter-spacing:1.5px;
    font-family: "Proxima Nova", Georgia, sans-serif;

    &:disabled{
      background-color: rgba(0,0,0,.5);
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.ImagingToothCharts{
  margin: 50px 0 100px 0;
  padding: 26px 50px 50px 50px;
  background-color: #FCFEFF;
  border-radius: 4px;

  &__heading{
    &>span
      {
        font-size: 17px;
        text-transform: capitalize;
      }
  }

  &__charts{
    display:flex;
    justify-content: space-evenly;
    text-align: center;

    .ToothMovementChart{

    }
  }


}

.Imaging {
  margin: 50px 0 100px 0;
  padding: 26px 40px 0 50px;
  background-color: #FCFEFF;
  border-radius: 4px;

  &__heading {
    margin-bottom: 35px;
    font-weight:bolder;
    display:flex;
    justify-content: space-between;

    > span{
      font-size:17px;
      text-transform: capitalize;
      //&:hover{
      //  cursor: pointer;
      //}
    }
  }

  &__slider {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }

  &__slider-button {
    color: #79929C;
    font-size: 20px;
    padding-bottom: 60px;
  }

  &__image-list {
    display: flex;
    justify-content: space-between;
    width: 73vw;
  }
}

.ImagingPhoto {


  &__photo {
    margin-bottom: 35px;
    width: 140px;
    height: 120px;
    background: linear-gradient(to bottom, #eee, #ddd);
    color: gray;
    border-radius:15px;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    text-align: center;
    font-size: 15px;
    letter-spacing: 1px;
  }
}
