.ConsultRequestDetail{

  display:flex;
  flex-direction: column;


  form{
    background-color: #F7F9FA;
    padding: 30px 100px;
    border: 1px solid #E6EDF0;
    border-radius: 5px;
    margin-bottom:30px;
  }

  &-canceled{

    .ConsultRequestDetail__row{
      &__statusButton{

        button{
          border-radius: 27px;
          padding: 8px 25px;
          width: 200px;
          color: #ffffff;
          background-color: #273238;
        }
      }
    }
  }

  &-complete{

    .ConsultRequestDetail__row{
      &__statusButton{
        button{
          border-radius: 27px;
          padding: 8px 25px;
          width: 200px;
          color: #ffffff;
          background-color: #273238;
        }
      }
    }
  }


  &__row{
    display:flex;
    flex-direction: row;
    justify-content: space-between;

    &__title{
      flex-direction: flex-start;
      font-size: 16px;

      span{
        margin-left: 15px;
        font-style: italic;
        color: rgba(51,51,51,.8);
      }
    }

    &__statusButton{
      font-size: 16px;
      display:flex;
      justify-content: flex-end;



      button{
        letter-spacing: .07em;
        margin-right: 10px;
        margin-left:10px;

        &:first-child{
          margin-left: 0;
        }

        &:last-child{
          margin-right:0;
        }

        &:hover{
          text-decoration: underline;
        }

      }
    }

    &__column{
      flex: 1 1;
      padding: 0 30px;

      input{
        &:disabled{
          cursor: default;
          background-color: #F7F9FA;
          box-shadow: none;
        }
      }

      &:first-child{
        padding-left:0px;
      }

      &:last-child{
        padding-right: 0px;
      }
    }
  }

}
