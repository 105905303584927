.ReactModal__Overlay{
  z-index:1600 !important;
}

.ImageSetUpload{

  /*margin: 0 1.8vw 100px 1.67vw;*/
  padding: 26px 100px 0 100px;
  background-color: #FCFEFF;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items:center;

  &__ImageActions{
    display:flex;
    justify-content: flex-end;
  }

  &__ImagesHeader{
    padding-bottom:20px;
    width: 1000px;
    display:flex;
    justify-content:space-between;

    &__status{
      margin-left:10px;
      font-style: italic;
      color: rgba(51, 51, 51, 0.8);

      &.Error{
        color: #d0021b;
      }
    }

    span{
      font-weight: bolder;
    }
  }

  &__Error{
    &__closeButton{

    }
  }

  &__form{

    &__Complete{

      .ImageSetUpload__form__images__row{

        .SingleImageUploadBox{

          .dz-message{
            color: #dddddd;
          }

          &.Error{
            .SingleImageUploadBox__detail{
              border: none;
            }
          }

          &.Fixing{
            .SingleImageUploadBox__detail{
              border: none;
            }
          }

          &__dropzone{
            .dz-preview{
              .dz-details{
                &__customImageDetails{
                  .removeIcon{
                    visibility:hidden;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__Cancel{

      .ImageSetUpload__form__images__row{

        .SingleImageUploadBox{

          .dz-message{
            color: #dddddd;
          }

          &.Error{
            .SingleImageUploadBox__detail{
              border: none;
            }
          }

          &.Fixing{
            .SingleImageUploadBox__detail{
              border: none;
            }
          }

          &__dropzone{
            .dz-preview{
              .dz-details{
                &__customImageDetails{
                  .removeIcon{
                    visibility:hidden;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__images{

      &__row{
        margin-bottom: 20px;
        display:flex;
        flex-direction: row;

        .SingleImageUploadBox{
          display:flex;
          //flex-grow: 1;
          flex-direction: column;
          border: 1px solid #E6EDF0;
          border-left: none;
          width:200px;

          .dot{
            opacity:0;
          }

          &.Good{
            .dot{
              opacity:1;
            }
          }

          &.Error{
            .SingleImageUploadBox__detail{
              border: 1px solid red;
            }

            .dot{
              background-color: #d0021b;
              opacity:1;
            }
          }

          &.Fixing{
            color: #ffc107;
            .SingleImageUploadBox__detail{
              border: 1px solid #ffc107;
            }

            .dot{
              background-color: #d0021b;
              /*background-color: #ffc107;*/
              opacity:1;
            }
          }

          &__header{
            text-align:center;
            padding: 12px 5px;
            background-color: #ffffff;
            color: #12171a;
            font-size: 11px;
            font-family: 'Proxima Nova Semibold';
            letter-spacing: 0.6px;
            border-bottom: 1px solid #E6EDF0;
            position: relative;
            white-space: nowrap;
            overflow-x: hidden;

            span{
              position: relative;
              text-transform: uppercase;
            }

            [tooltip]::before{
              opacity: 1;
              border-top-color: #405159;
              border-width: 4px 2px 0 2px;
              top:-9px;
            }

            [tooltip]::after{
              font-size: 9px;
              font-family: 'Proxima Nova Semibold';
              text-transform: capitalize;
              margin-left:30px;
              letter-spacing: 0.6px;
              white-space: nowrap;
              padding: 4px 10px;
              border-radius: 3px;
              top:-9px;
              opacity: 1;
            }

            .dot{
              position:absolute;
              top: 8px;
              right:5px;
            }
          }

          &__detail{
            height: 160px;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &__dropzone{
            height: 100%;
            border: none;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            width:50px;
            height:50px;

            &.dz-started{
              .dz-message{
                display:none;
              }
            }

            .dz-message{

              color: #7a8f99;
              display:flex;
              justify-content: center;

              .default-icon{

              }

              &.dz-default{
                display:none;
              }
            }
            .dz-preview{
              + .dz-preview {
                display:none;
              }

              &.dz-error{
                .dz-error-mark{
                  &__customErrorMark{
                    margin-left: 0;
                    margin-top:0;
                  }
                }
              }
              &.dz-success{
                .dz-success-mark{
                  &__customSuccessMark{
                    margin-left:0;
                    margin-top:0;
                  }
                }
              }
              .dz-image{
                &__customImagePreview{
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: center;
                  height: 160px;
                  width: 200px;
                  border-radius:0;

                  &.previewBlank{
                    background: linear-gradient(to bottom, #eee, #ddd);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 3px;
                    font-family: 'Proxima Nova';
                    font-size: 16px;
                    color: gray;
                  }
                }

              }
              .dz-details{
                &__customImageDetails{
                  padding: 0;

                  .removeIcon{
                    height: 3em;

                    &-cancel{
                      visibility:hidden;
                    }

                    .iconWrapper{
                      &:hover{
                        cursor: pointer;
                      }
                      top: 7px;
                      right: 7px;
                      position: absolute;
                      width:24px;
                      height:24px;
                      svg{
                        pointer-events:none;
                        fill: #d8d8d8;
                        color: #d0021b;
                        //z-index: 21; /* img is at 10, detamils is at 20*/
                      }

                      &__left{
                        left:7px;
                      }

                      &__right{
                        right:5px;
                      }
                    }
                  }
                  .dz-size{
                    &__customImageSize{
                      margin-bottom: 1em;
                      margin-left: 1em;
                      margin-right: 1em;
                    }
                  }
                  .dz-filename{
                    &__customFilename{

                    }
                  }
                }
              }

            }
          }
        }

        .SingleImageUploadBox:first-child{
          border-left:1px solid #E6EDF0;
        }

        .SingleImageUploadBox:last-child{

        }
      }
    }
    &__actions{
      display:flex;
      justify-content: flex-end;
      padding-top: 30px;
      padding-bottom:100px;



      &__submitButton{
        padding: 10px 20px;
        width: 180px;
        background-color: black;
        text-align: center;
        border-radius: 100px;
        color:white;
        letter-spacing:1.5px;

      }
    }

  }
}
