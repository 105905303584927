.ConsultRequestMessages{

  &__navRow{
    width:100%;
    display:flex;
    justify-content: flex-end;
    margin-bottom: 50px;

    a{
      display: flex;
      align-items: center;
      letter-spacing: 1px;

      svg {
        font-size:14px;
      }
    }
  }

  &__row{
    width:100%;
    display:flex;
    justify-content: space-between;
    margin-bottom:20px;

    .sectionTitle{
      font-size: 15px;
      letter-spacing: 1px;
      font-family: 'Proxima Nova Semibold';

      span{
        margin-left: 5px;
        font-family: 'Proxima Nova';
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;

        &.sectionTitle__statusLabel{
          color: dimgray;
          letter-spacing: .2px;
          font-size: 12px;
        }
      }


      &__status{
        &-incomplete{
          color: #d0021b
        }

        &-complete{
          color: rgba(51, 51, 51, 0.8);
        }


      }


    }

    a{
      display: flex;
      align-items: center;
      letter-spacing: 1px;

      svg {
        font-size:14px;
      }
    }
  }

  &__staffReply{
    display: flex;


    &__button{
      display: flex;
      justify-content: center;
      font-size: 16px;
      background-color: #F7F9FA;
      padding: 20px 0;
      border: 1px solid #E6EDF0;
      border-radius: 5px;
      margin-bottom: 50px;
      width:100%;
      align-items:center;

      &:first-child{
        margin-right:30px;
      }

      &:last-child{
        margin-left:30px;
      }
    }
  }

  &__Reply{
    display: flex;
    justify-content: center;
    font-size: 16px;
    background-color: #F7F9FA;
    padding: 20px 0;
    border: 1px solid #E6EDF0;
    border-radius: 5px;
    margin-bottom: 50px;
    width:100%;
    align-items:center;

    span{
      margin-left:5px;
    }
  }

  &__Message{

    &-new{
      .ConsultRequestMessages__Message__triangle{
        &::before{
          border-bottom-color:#a8b4ec;
        }
      }

      .ConsultRequestMessages__Message__form{
        border-color:#a8b4ec;
      }
    }

    &__actionsContainer{
      margin-top:-30px;
    }

    &__actions{
          font-size: 16px;
          display:flex;
          justify-content: flex-end;
          margin-bottom:50px;


          button{
            border-radius: 27px;
            padding: 8px 25px;
            width: 200px;
            color: #ffffff;
            background-color: #273238;
            font-size:15px;

            &:first-child{
              margin-right:10px;
            }
          }
    }

    textarea.form-control{

      &:focus{
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    .ConsultRequestMessages__Message__attachmentsContainer{
      display:none;
      background-color:transparent;
      border: 1px solid #e6edf0;
      border-top:none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 20px;
      min-height: 170px;
      position: relative;

      &::before{
        padding-top: 1px;
        content: "";
        display: block;
        border-bottom: 1px solid #e6edf0;
      }

      div.filepicker{
        background-color:transparent;
        border:none;
        padding: 0;
        min-height: 170px;
        position: relative;
        width:100%;
        left: 0;
        top: 0;
        display:flex;

        .dz-message{
          margin: 50px 12px 0 0;
          font-family: 'Proxima Nova Bold';
          letter-spacing: 0.9px;
          font-size: 14px;
          display:flex;
          align-items: center;
          line-height:17px;
          color: #7a8f99;
          position: absolute;
          right: 12px;
          bottom: 0;

          svg{
            font-size:15px;
            margin-right:5px;
          }
        }
      }
    }

    &-edit{
      .ConsultRequestMessages__Message__attachmentsContainer{
        background-color:#ffffff;

        div.filepicker{
          background-color:#ffffff;
          border:none;
          padding: 0;
          min-height: 170px;
          position: relative;
          width:100%;
          left: 0;
          top: 0;

          .dz-message{
            margin: 50px 12px 0 0;
            font-family: 'Proxima Nova Bold';
            letter-spacing: 0.9px;
            font-size: 14px;
            display:flex;
            align-items: center;
            line-height:17px;
            color: #7a8f99;
            position: absolute;
            right: 12px;
            bottom: 0;

            svg{
              font-size:15px;
              margin-right:5px;
            }

          }

        }

      }


    }

    &__triangle{
      height:31px;
      position: inherit;
      width:100%;
      margin-bottom:-1px;

      &::after{
        content: '';
        margin-top:1px;
        display: block;
        position: relative;
        float:right;
        width: 0;
        height: 30px;
        right: 38px;
        z-index:4;
        border-right:30px solid transparent;
        border-left: 30px solid transparent;
        border-bottom: 30px solid #F7F9FA;
      }

      &::before{
        content: '';
        display: block;
        position: relative;
        float:right;
        width: 0;
        height: 31px;
        right: 99px;
        border-right:31px solid transparent;
        border-left: 31px solid transparent;
        border-bottom: 31px solid #E6EDF0;
      }
    }


    &__form{
      background-color: #F7F9FA;
      padding: 50px 100px;
      padding-top: 30px;
      border: 1px solid #E6EDF0;
      border-radius: 5px;
      margin-bottom:50px;

      // test
      position:relative;

      &__editRow{
        display:flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        button{
          margin-left:25px;
        }
      }

      &__details{
        display:flex;
        flex-direction:column;

        .form-control{
          border: solid 1px #e6edf0;
        }

        .form-control[disabled]{
          cursor: default;
          background-color:#F7F9FA;
          box-shadow: none;
        }

        .Error__Label{
          height:28px;
        }

        &__firstRow{
          display:flex;
          justify-content: space-between;
          &__input{
            flex: 1;
            padding: 0 30px;
            &:first-child{
              padding-left:0;
            }
            &:last-child{
              padding-right:0;
            }
          }
        }

        &__secondRow{

          textarea{
            resize:none;
            padding: 6px 15px;
          }
        }

      }

    }

    &__attachments{
      display:flex;
      padding-top: 20px;
    }

    &-attachments{
      textarea.form-control{
        border-bottom:none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

      }

      textarea + .Error__Label{
        background-color: transparent;
        border-left: 1px solid #e6edf0;
        border-right: 1px solid #e6edf0;
        padding-left: 20px;
      }


      .ConsultRequestMessages__Message__attachmentsContainer{
        display:block;
      }

      &.ConsultRequestMessages__Message-edit{
        textarea.form-control{
        }

        textarea + .Error__Label{
          background-color: #ffffff;
        }
      }
    }

    .dropzone{
      background-color: #ECECEC;

      .dz-preview{
        background-color: inherit;
      }
    }

    .dz-message{
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size:14px;
        font-family: 'Proxima Nova Bold';
      }
    }

    .removeIcon{
      height: 1.3em;
      .iconWrapper{
        &:hover{
          cursor: pointer;
        }
        top: 5px;
        position: absolute;
        width:24px;
        height:24px;
        svg{
          pointer-events:none;
          fill: rgb(155,155,155);
          color: black;
          //z-index: 21; /* img is at 10, detamils is at 20*/
        }

        &__left{
          left:7px;
        }

        &__right{
          right:5px;
        }
      }
    }
  }
}
