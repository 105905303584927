/* home.style */

.LanguageBar{
  position: absolute;
  right:0;
  padding: 50px 100px 0 0 ;
  font-size: 20px;
  color: #405159;
  font-family: 'Noto Sans SC', 'Proxima Nova' , sans-serif;


  &__divider{
    margin: 0 10px;
  }

  &__en{
    font-family: 'Proxima Nova' , sans-serif;

    &:hover{
      cursor:pointer;
    }
  }

  &__zh{
    font-family: 'Noto Sans SC', sans-serif;

    &:hover{
      cursor:pointer;
    }
  }
}

.Breadcrumb{
  letter-spacing: 1px;
  font-size: 15px;
  font-family: 'Proxima Nova Semibold';
  color: #9b9b9b;
  margin-bottom: 15px;
  display:flex;

  &-active{
    color: #405159;
  }

  &__text{
    line-height:21px;
  }

  &__icon{
    margin: 0 5px 0 1px;
  }
}

.Header {
  z-index: 1;
  background-color: #253238;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 14px 0px rgba(0,0,0,.5); /*#696464;*/

  &__logo {
    display: inline-block;
    color: white;
    width: 229px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 2.4px;

    a {
      color: inherit;
      text-decoration:none;
      &:hover{color: inherit;}
      &:link{color: inherit;}
      &:visited{color: inherit;}
      &:active{color: inherit;}
    }
  }

  &__right-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__search-wrapper {
    position: relative;
    display: inline-block;
    width:250px;
    margin-right:40px;
  }

  &__search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    left: 14px;
    color: white;
    line-height: 32px;
    font-size: 14px;
  }

  &__search {
    /*display: inline-block;
      padding-left: 40px;
      margin-right: 40px;
    */
    position: relative;
    height: 32px;
    background-color: #4F595C !important;

    .Select-control{
      background-color: #4F595C !important;
      padding-right:10px;
      border: none;
      border-radius: 0px;
      color: white;
      width: 100%;

      .Select-placeholder{
        padding-left:40px;
        color: white;
      }

      .Select-value{
        padding-left:40px;
        height: 34px;
        padding-right: 10px;
        vertical-align: middle;
      }

      .Select-input{
        padding-left:40px;
      }

      .Select-arrow-zone{
        display:none;
      }
    }

    &::placeholder {
      color: white;
      display: inline-block;
      margin-left: 40px;
    }
  }

  &__divider {
    display: inline-block;
    height: 40px;
    background: #2D4249;
    width: 1px;
    margin-right: 10px;
  }

  &__notifications{
    text-align:center;
    display: inline-block;
    margin-top: 8px;
    padding: 0 25px;

    &:hover{
      cursor:pointer;
    }

    &--active{
      .Header__notifications__button{
          .notificationDot{
            fill: #a8b4ec;
          }
        }
    }

    &__button {
      display: inline-block;
      margin-right: 23px;
      margin-top:7px;

      .notificationDot{
          fill: #253238;
      }
    }

    &__dropdown{
      margin-top: 0;
      padding:0;
      opacity:1 !important;
      cursor:default;

      &.__react_component_tooltip{
        &.place-bottom{
          opacity:1;
          box-shadow: none;

          &:after{
            left:auto;
            right:7px;
            top:-10px;
            border-bottom-width:10px;
            border-left-width:4px;
            border-right-width: 4px;
          }
        }
      }
    }
  }

  &__user-info {
    display: flex;
    align-items: center;
    width: 280px;
  }

  &__user-photo {
    margin-right: 15px;
    display: inline-block;
  }
  &__user-info-right-wrapper {
    margin-right: 75px;
  }

  &__user-id {
    font-size: 14px;
    font-weight:bolder;
    color: white;
    display: block;
  }

  &__user-type {
    font-size: 12px;
    color: rgb(122, 143, 153);
    display: block;
  }



  &__menu{
    width:30px;

    &__dropdown{

      &.__react_component_tooltip{
        &.place-bottom{
          opacity:1;
          box-shadow: 0 5px 10px 0px #F6F8F9;

          &:after{
            left:auto;
            right:6px;
          }
        }
      }

      &__menu{
        &__item{
          font-size: 16px;
          text-align:right;
          padding: 8px 0px;
          font-family: 'Proxima Nova';

          &:hover{
            cursor:pointer;
          }
        }
      }

      a{
        &:hover{
          text-decoration:none;
        }
      }

      &__text{
        &:hover{
          text-decoration: none;

          span{
            text-decoration: none;
          }
        }
        span{
          text-decoration: none;
        }
      }

    }

    &__popup{

      a{
        &:hover{
          text-decoration:none;
        }
      }

      &__text{
        &:hover{
          text-decoration: none;

          span{
            text-decoration: none;
          }
        }
        span{
          text-decoration: none;
        }
      }

    }



    &:hover{
      cursor:pointer;
    }

    &__icon{
      font-size:18px;
      color: white;
    }

    &__menuItem{
      font-size:18px;
      font-family: 'Proxima Nova', Georgia, sans-serif;
    }
  }
}

.Sidebar {
  width: 90px;
  padding-top: 40px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 6px 8px 10px 0px #F6F8F9;

  a{
  }

  &__link {
    stroke-width:1.2;
    margin-bottom: 50px;
    text-align: center;

    .Sidebar__svg{
        margin-left:24px;
    }

    &--active{
      stroke-width:1.6;
      border-left-style: solid;
      border-left-width: 4px;
      border-left-color: #12171A;

      .Sidebar__svg{
        margin-left:20px;
      }
    }

  }

  &__icon {
    stroke-width:1.2;
    fill:white;
    stroke:#566973;

    &-messaging{
      stroke-width:0;

      &--active{

          path{
            stroke-width:1;
          }
      }

    }

    &-notepad{
      stroke-width:0;

      &--active{
        stroke:#12171a;
       stroke-width:.5;

    }
    }

    &--active{
      stroke:#12171a;
      stroke-width:1.6;

    }
  }

}

.Loader {
  position: fixed;
  top: inherit;
  left: inherit;
  width: 100%;
  height: 100%;
  background: #ffffff url('../images/loader.gif') no-repeat center center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  background-size: 32px 32px;
  display: none;

  &__show{
    display:block;
  }
}
