@import url('./fonts.css')
*{
	margin:0px;
	font-family: 'Proxima Nova', Georgia, sans-serif;
}

html {
  font-family: 'Proxima Nova', Georgia, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
}

.clickable:hover{
	cursor: pointer;
}

div#app{
  min-width:1250px;
	font-family: "Proxima Nova", Georgia, sans-serif;
  color: #12171a;
}

#app a, #app a:hover{
	color:inherit;
	text-decoration: none;
}

.react-datepicker-wrapper{
  display:block;
}

.react-datepicker__header{

}

.react-confirm-alert{
}

.react-confirm-alert-body{
  font-family: 'Proxima Nova', Georgia, sans-serif;
  padding: 30px 50px;
  background-color: #FAFAFA;
  text-align: center;
  width: 460px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #333;
  border-radius: 4px;
}

.react-confirm-alert-body h1{
  font-weight: 700;
  font-size:16px;
}

.react-confirm-alert-button-group{
  display:flex;
  justify-content: center;
  margin-top: 30px;
}

.react-confirm-alert-button-group > button{
  padding: 10px 25px;
  width: 130px;
  background-color: black;
  text-align: center;
  border-radius: 100px;
  color: white;
  letter-spacing: 1.5px;
  font-family: "Proxima Nova", Georgia, sans-serif;
}

.react-confirm-alert-button-group > button:first-child{
    margin-right: 15px;
}

[tooltip]{
  /*margin:20px 60px;
  position:relative;
  display:inline-block;*/
}
[tooltip]::before {
    content: "";
    position: absolute;
    top:-6px;
    left:50%;
    transform: translateX(-50%);
    border-width: 4px 6px 0 6px;
    border-style: solid;
    border-color: rgba(0,0,0,0.7) transparent transparent     transparent;
    z-index: 99;
    opacity:0;
}

[tooltip-position='left']::before{
  left:0%;
  top:50%;
  margin-left:-12px;
  transform:translatey(-50%) rotate(-90deg)
}
[tooltip-position='top']::before{
  left:50%;
}
[tooltip-position='bottom']::before{
  top:100%;
  margin-top:8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg)
}
[tooltip-position='right']::before{
  left:100%;
  top:50%;
  margin-left:1px;
  transform:translatey(-50%) rotate(90deg)
}

[tooltip]::after {
    content: attr(tooltip);
    position: absolute;
    left:50%;
    top:-6px;
    transform: translateX(-50%)   translateY(-100%);
    background: rgba(0,0,0,0.7);
    text-align: center;
    color: #fff;
    padding:4px 2px;
    font-size: 12px;
    min-width: 80px;
    border-radius: 5px;
    pointer-events: none;
    padding: 4px 4px;
    z-index:99;
    opacity:0;
    white-space:normal;
}

[tooltip-position='left']::after{
  left:0%;
  top:50%;
  margin-left:-8px;
  transform: translateX(-100%)   translateY(-50%);
}
[tooltip-position='top']::after{
  left:50%;
}
[tooltip-position='bottom']::after{
  top:100%;
  margin-top:8px;
  transform: translateX(-50%) translateY(0%);
}
[tooltip-position='right']::after{
  left:100%;
  top:50%;
  margin-left:8px;
  transform: translateX(0%)   translateY(-50%);
}

[tooltip]:hover::after,[tooltip]:hover::before {
   opacity:1
}

.dot {
  height: 6px;
  width: 6px;
  background-color: #4a90e2;
  border-radius: 50%;
  display: inline-block;
}

button{
  font-family: 'Proxima Nova Semibold';
  font-size: 15px;
}

button[disabled]{
  background-color:#f3f0f0;
}

input.form-control{
  height: 40px;
}

input.form-control.Input_Error{
  border-color: #d0021b;
}

input.form-control.Input_Error[disabled]{
  border-color: #e6edf0;
}

[disabled].form-control + .Error__Label{
  visibility: hidden;
}

input.form-control,
textarea.form-control{
  border: solid 1px #e6edf0;
  box-shadow: none;
  border-radius: 3px;
  color: #12171a;
}

div.Select-control{
  border-color: #e6edf0;
  height: 40px;
  box-shadow: none;
  border-radius: 3px;
  color: #12171a;
}

.Select.is-disabled + .Error__Label {
  visibility: hidden;
}

.Select.is-disabled > .Select-control{
  border-color: #e6edf0;
}

div.Select-placeholder{
  padding-top:2px;
}

.Select div{
  padding-top:2px;
}

label{
  font-size:15px;
  font-family: 'Proxima Nova Semibold';
  letter-spacing: 1px;
  color: #12171a;
}

.procedureForm{
    display: 'flex';
    flex-wrap: 'wrap';
}

.procedureFormControl{
  min-width:150px !important;
  margin-right: 15px !important;
  min-height:50px;
}

button.appointmentForm{
  border-radius: 30px;
  margin:10px;
}

.popUpForm .title h2{
  font-size:18px;
  font-family: 'Proxima Nova', Georgia, sans-serif;
  font-weight:600;
}


.popUpForm .contentText{
  font-size:14px;
  font-family: 'Proxima Nova', Georgia, sans-serif;
}


button.patientForm{
  border-radius: 30px;
  margin-top: 22px;
}

button.formSubmit.right{
  border-radius:30px;
  font-size:14px;
  margin-left:10px;
}

button.primary{
  background-color:#253239;
  color: white;
}


button.right{
  margin-left:10px;
}

/*
  Video Chat Page
*/
.videoChatPage + .navigationBar {
  display:none !important;
}

.videoChatBoxes > div:not(:first-child) > div > div{
  right:0;
  bottom:0;
  margin-right:20px;
  margin-bottom:20px;
  z-index:2;
  position: absolute;
}

.videoChatBoxes > div:first-child > div > div{
  height:100% !important;
  width:100% !important;
}

.videoChatBoxes > div:only-child > div > div{
  height:100% !important;
  width:100% !important;
}
.videoChatBoxes > div:first-child  > div{
  height:100% !important;
  width:100% !important;
}

.videoChatBoxes > div:only-child  > div{
  height:100% !important;
  width:100% !important;
}

.videoChatPage .videoChatTitleOverlay {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    box-align: center;
    align-items: center;
    flex-direction: column;
    pointer-events:none;
}

.videoChatPage .videoChatTitleContent{
   margin-top: 32px;
   min-width: 200px;
   -webkit-flex: 1;
   flex: 1;
   -webkit-box-orient: vertical;
   box-orient: vertical;
   -webkit-flex-direction: column;
   flex-direction: column;
   display: -webkit-box;
   display: -moz-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -webkit-box-align: center;
   box-align: center;
   -webkit-align-items: center;
   align-items: center;
   box-pack: center;
   -webkit-justify-content: center;
   justify-content: center;
   background-color: rgb(0,0,0,.2);
}

.videoChatPage .videoChatButtonsOverlay {
    bottom: 0;
    height: auto;
    left: 0;
    position: absolute;
    text-align: center;
    right: 0;
}

.videoChatButtons {
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    pointer-events: none;
    position: relative;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-transition: transform 250ms ease-out;
    transition: transform 250ms ease-out;
}

.videoChatButtons > .endVideoChat{
    pointer-events:all;
    margin-bottom:35px !important;
}

.videochat-subscriber{
  width:48%;
  float:left;
}

.videochat-full{
  height:700px;width:100%;
}

.videochat-small{
  right:0;bottom:0;
  margin-right:20px;margin-bottom:20px;
  z-index:2;
}

video.OT_video-element{
  object-fit:contain !important;
}
