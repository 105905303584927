/* admin.style */

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  display: block;
}

button {
  border: 0;
  border-radius: 0;
  padding: 0;
  background-color: inherit;
  cursor: pointer;
}

input {
  border: 0;
}

input:focus,
button:focus {
  outline: none;
}

td {
  padding: 0;
}

h1,
h2 {
  margin: 0;
}

.ERPDescriptionBox{
  &__row{
    display: flex;
    min-height: 20px;
    padding-left: 25px;
    margin-left: 0;
    margin-right: 0;
    width: 70%;
  }

  &__cell{
    width: 25%

  }
}

.AdminList{
  .cell{

    &.organization{
      flex: 3 1;
    }

    &.practiceName{
      flex: 3 1;
    }

    &.email{
      flex: 2 1;
    }

    &.phoneNumber{
      flex: 2 1;
    }

    &.date{
      flex: 2 1;
    }
  }
}

.AdminSettings{
  &__erpRow{
    display:flex;
    flex-direction: column;
    padding-bottom: 25px;
  }

  &__wholesaler{
    min-width: 400px;
  }

  &__country{
    padding-bottom: 30px;
  }

  &__buttons{
    padding-bottom: 30px;

    button{
      padding: 10px 0;
      width: 170px;
      background-color: #273238;;
      text-align: center;
      border-radius: 27px;
      color:white;
      letter-spacing:1.5px;
      align-self:flex-start;
    }
  }

  &__halfRow{
      margin-right:10px;
  }

  &-gender{
    min-width:150px;
  }

  .react-phone-number-input__icon{
    line-height: 1px;
    border:none;
  }
}

.Admin {
  display: grid;
  grid-template-columns: 90px auto [end];
  grid-template-rows: 72px auto [end];
}

.Admin .Header {
  grid-row: 1 / span 1;
  grid-column: 1 / end;
}

.Admin .Sidebar {
  grid-row: 2 / end;
  grid-column: 1 / span 1;
}

.Admin .ContentWrapper {
  grid-row: 2 / end;
  grid-column: 2 / end;
  background-color: #ECEEEF;
}

.Admin .TabContent{
  &__left-section{
    margin-bottom:22px;
    overflow:visible;
  }
}

.Admin .PageContent {
  margin: 22px 1.8vw 24px 1.67vw;
  background-color: white;
  display: flex;
  align-items: stretch;
  padding-bottom:20px;



  button {
    margin-right:10px;
  }

  .buttons-group{
    margin-top:15px;
  }

  &__AccountList{

    .user-table-row{
      &:hover{
        cursor:pointer;
      }
    }
  }

  &__Availability{
    .time-row{
      height:25px;
    }
  }

  &__left-section {
    padding: 24px 5.56vw 0 3.47vw;
    width: 66.66vw;
    background-color: #FDFEFF;
  }

  &__teeth-diagram {
    margin-left: 80px;
  }

  &__descriptions-heading {
    font-size: 19px;
    font-weight:bolder;
    padding-bottom: 12px;
    border-bottom: 2px solid #EAEFF0;
    margin-bottom: 19px;
  }

  &__descriptions-body {
    clear: both;
    font-size: 14px;
    line-height: 1.4;
    padding-bottom: 50px;
  }

  &__Billing {
    padding: 24px;
    margin-bottom:60px;
  }

  &__Insurance {
    padding: 24px;
    margin-bottom:60px;
  }

  .UserAccounts{
    &__buttonGroup{
      margin-right:10px;
    }
  }
}
