.ForgotPassword{

  background-color: #F7F9FA;
  height:100vh;

  &__Form{
    background-color:white;
    padding: 30px;
    width:550px;

    &__Message{
      background-color: #F7F9FA;
      padding: 30px 30px;
      font-size:14px;
      text-align:center;
      line-height: 30px;
    }

    &__Header{
      padding:15px 0;
      font-size:16px;
      text-align:left;
      border-bottom: solid 1px lightgray;
      display:flex;
      justify-content: space-between;

      a{
        span{
          color:gray;
          font-size:14px;
        }
      }
    }

    &__SubHeader{
      padding:15px 0;
      font-size:12px;
      text-align:left;
      padding-bottom:0;
    }

    &__FormError{
      height:30px;

      .Error{
        font-size:14px;
      }
    }

    &__Form{
      &-main{
        background-color: #F7F9FA;
        padding: 30px 100px
      }
    }

    &__Help{
      text-align:center;
    }

    &__forgotText{
      margin-top: 20px;

      a{
        span{text-decoration: underline;}
      }

    }

    &__registerText{
      margin-top: 90px;

      &__LineOne{
        display:block;
      }

      &__LineTwo{
        margin-top:5px;

        span{text-decoration: underline;}
      }
    }

    &__FormButton{
      display:flex;
      justify-content:center;
    }

    .Button{
      width: 250px;
      padding: 15px 20px;
      margin: 0;
      font-size:14px;
    }

  }
}
